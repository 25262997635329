<template>
  <div class="woodcraft fill-height" v-if="data">
    <BackBtn class="woodcraft__back" />
    <div class="woodcraft-inner--large">
      <ArticleHeader :data="data.header" />

      <div class="woodcraft__content">
        <div v-if="data.body && data.body[0]">
          <h3>Photos</h3>
          <div class="woodcraft__content__gallery">
            <g-picture
              v-for="(item, index) in data.body"
              :key="`item-${index}`"
              class="woodcraft__picture-outer"
              :content="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Error />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/woodcraft'
import { useRoute } from 'vue-router'

import ArticleHeader from '@/components/article/Header.vue'
import BackBtn from '@/components/BackBtn.vue'
import Error from '@/components/Error.vue'

export default defineComponent({
  name: 'woodcraft',
  components: {
    ArticleHeader,
    BackBtn,
    Error,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      data,
    }
  },
})
</script>

<style lang="scss" scoped>
.woodcraft {
  @include details-intro;

  position: relative;
  background-color: $c-white;
}

[class*='woodcraft-inner--'],
.woodcraft-inner {
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: $spacing * 3 0;
  z-index: 0;

  @include mq(m) {
    width: calc(100% - #{$spacing * 4});
  }

  &[class*='--large'] {
    max-width: 60rem;
    @include mq(m) {
      max-width: 72rem;
    }
  }
}

.woodcraft__content__gallery {
  margin-top: $spacing / 2;
}

.woodcraft__picture-outer {
  margin-inline: auto;
  margin-bottom: $spacing;
  max-width: 60rem;
}
</style>
