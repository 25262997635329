
import { defineComponent, ref, onMounted } from 'vue'

import Btn from '@/components/Btn.vue'

export default defineComponent({
  name: 'error',
  components: {
    Btn,
  },
  setup() {
    const isOffline = ref<boolean>(!navigator.onLine)
    const showAlert = ref<boolean>(false)

    onMounted(() => {
      showAlert.value = false

      window.setTimeout(() => {
        showAlert.value = true
      }, 2500)
    })

    return {
      showAlert,
      isOffline,
    }
  },
})
