<template>
  <div class="error fill-height">
    <transition name="fade">
      <div class="error-inner" v-if="showAlert">
        <div v-if="isOffline">
          <SvgSprite class="icon" symbol="ui-offline" size="60" />
          <h1>Hors connexion</h1>
          <h3>Il semblerait que tu sois hors connexion.</h3>
          <p>
            Connecte toi à internet pour accéder à ce contenu.
          </p>
        </div>

        <div v-else>
          <SvgSprite class="icon" symbol="ui-error" size="60" />
          <h1>Erreur 404</h1>
          <h3>Zut, la page que tu cherches est introuvable !</h3>
          <p>
            Le lien est peut-être corrompu... Nous te conseillons de retourner
            sur l'Accueil
          </p>
        </div>

        <router-link :to="{ name: 'Home' }">
          <Btn>
            <span>Se rendre sur l'Accueil</span>
            <SvgSprite
              class="icon-arrow-right"
              symbol="ui-arrow-right"
              size="16"
            />
          </Btn>
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'

import Btn from '@/components/Btn.vue'

export default defineComponent({
  name: 'error',
  components: {
    Btn,
  },
  setup() {
    const isOffline = ref<boolean>(!navigator.onLine)
    const showAlert = ref<boolean>(false)

    onMounted(() => {
      showAlert.value = false

      window.setTimeout(() => {
        showAlert.value = true
      }, 2500)
    })

    return {
      showAlert,
      isOffline,
    }
  },
})
</script>

<style lang="scss" scoped>
.error {
  position: relative;
  background-color: $athens-gray;
  text-align: center;

  h1 {
    margin-top: $spacing;
    color: $tangerine;
    text-transform: uppercase;
  }

  h3 {
    margin-bottom: $spacing * 0.5;
  }

  p {
    margin-bottom: $spacing * 3;
  }

  a {
    margin: auto;
    display: inline-flex;

    ::v-deep(button) {
      display: flex;
      align-items: center;
      width: auto;
      text-align: left;
      text-transform: none;

      font-size: 1.4rem;

      svg {
        margin-left: $spacing;
      }

      @include mq(m) {
        font-size: 1.8rem;
      }
    }
  }

  .icon-arrow-right {
    stroke: $tangerine;
    stroke-width: 2;
  }
}

[class*='error-inner--'],
.error-inner {
  position: relative;
  width: calc(100% - #{$spacing * 4});
  max-width: 80rem;
  margin: 0 auto;
  padding: $spacing * 4 0;
  z-index: 0;

  @include mq(m) {
    width: calc(100% - #{$spacing * 4});
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
